const sendContactFormFilledEvent = () => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event: 'configurator_form',
  });
};

export default sendContactFormFilledEvent;
