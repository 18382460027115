import { object, string } from 'yup';

const getValidationSchema = (intl) => object().shape({
  email: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
  content: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
});

export default getValidationSchema;
