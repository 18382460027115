import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Formik, Form } from 'formik';
import _forEach from 'lodash/forEach';
import { useSnackbar } from 'react-simple-snackbar';

import InputText from 'components/InputText';
import sendContactFormFilledEvent from 'utils/sendContactFormFilledEvent';

import './ContactForm.scss';
import getValidationSchema from './validation';

const ContactForm = () => {
  const intl = useIntl();
  const [openSnackbar] = useSnackbar();

  const onFormSubmit = (values) => {
    const data = {
      mail: values.email,
      content: values.content,
    };

    if (typeof window !== 'undefined') {
      const formData = new window.FormData();

      _forEach(data, (el, key) => {
        formData.append(key, JSON.stringify(el));
      });

      window.fetch('/sendContactMail', {
        method: 'post',
        body: formData,
      }).then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }

        openSnackbar(intl.formatMessage({ id: 'contactFormSent' }));
        sendContactFormFilledEvent();
      }).catch(() => {
        openSnackbar(intl.formatMessage({ id: 'contactFormNotSent' }));
      });
    }
  };

  return (
    <div className="contact-form">
      <h1>
        <FormattedMessage id="contactFormTitle" />
      </h1>
      <Formik
        initialValues={{
          email: '',
          content: '',
        }}
        onSubmit={(values) => {
          onFormSubmit(values);
        }}
        validationSchema={getValidationSchema(intl)}
      >
        <Form>
          <div className="form-wrapper">
            <div className="row">
              <InputText
                name="email"
                label={<FormattedMessage id="contactFormEmail" />}
              />
            </div>
            <div className="row">
              <InputText
                name="content"
                label={<FormattedMessage id="contactFormContent" />}
                isMultiple
              />
            </div>
          </div>
          <div className="button-wrapper">
            <button className="btn" type="submit">
              <FormattedMessage id="contactFormSend" />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
