import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import ContactForm from 'components/ContactForm';

import config from 'config';

import './Contact.scss';

const Contact = () => (
  <div className="contact-page">
    <div className="line" />
    <div className="content-wrapper">
      <h1>
        <FormattedMessage id="contactPageTitle" />
      </h1>
      <div className="inline mobile-inline">
        <div className="contact-box">
          <div className="description-text">
            {config.address.street}
          </div>
          <div className="description-text">
            {config.address.city}
          </div>
        </div>
        <div className="contact-box dark">
          <div className="description-text">
            {config.contact.mail}
          </div>
        </div>
      </div>
    </div>
    <div className="form-wrapper">
      <ContactForm />
    </div>
  </div>
);

export default Contact;
